import React from "react";

import utils from "../../../views/utils";
import { useSections } from "../../../../../../contexts/ParticipantSections/ParticipantSectionsContext";
import { useParticipantSteps } from "../../../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext";

export const useNavigationData = (stepId: string, sectionId: string) => {
  const { listParticipantSections = [] } = useSections();
  const { participantSteps } = useParticipantSteps();

  const sections = listParticipantSections || [];
  const steps = participantSteps?.steps || [];

  const firstSection = sections[0];
  const firstStep = steps[0];
  const lastSection = sections[sections.length - 1];
  const lastStep = steps[steps.length - 1];

  const previousEntity = React.useMemo(() => {
    return utils.getFollowingEntity(
      stepId,
      sectionId || null,
      {
        listParticipantSections,
        participantSteps,
      },
      'previous',
    )
  }, [stepId, sectionId]);
  const nextEntity = React.useMemo(() => {
    return utils.getFollowingEntity(
      stepId,
      sectionId || null,
      {
        listParticipantSections,
        participantSteps,
      },
      'next',
    )
  }, [stepId, sectionId]);

  const skipPrevious = sectionId
    ? firstSection?.id === sectionId
    : firstStep?.id === stepId;
  const skipNext = sectionId
    ? lastSection?.id === sectionId
    : lastStep?.id === stepId;

  return {
    previousEntity,
    nextEntity,
    skipPrevious,
    skipNext,
  }
}
